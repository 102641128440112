import PostLayout from 'components/PostLayout'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import blogMenu from 'components/Blog/blogMenu'
import Layout from 'components/Layout'
import { Posts } from 'components/Blog'
import Pagination from 'components/Pagination'
import { NewsletterForm } from 'components/NewsletterForm'
import SupportCTA from 'components/SupportCTA'
import ArrayCTA from 'components/ArrayCTA'
import { useLayoutData } from 'hooks/useLayoutData'
import { getDirection, removeLanguagePrefix } from 'utils/translations'

const BlogCategory = ({
    data: {
        allPostsRecent: { edges: allPostsRecent },
    },
    pageContext: { numPages, currentPage, base, menu, brand, locale },
}) => {
    const dir = getDirection(locale)
    const cleanedBase = removeLanguagePrefix(base, 'ar')
    return (
        <Layout>
            <SEO title={`All posts - ${brand}`} />

            <PostLayout
                dir={dir}
                breadcrumb={[{ name: 'Blog', url: '/blog' }, { name: 'All' }]}
                article={false}
                title="Blog"
                menu={menu}
                hideSidebar
                hideSurvey
            >
                <Posts
                    title="All posts"
                    action={
                        <p className="m-0 text-sm font-semibold leading-none opacity-50">
                            Page {currentPage} of {numPages}
                        </p>
                    }
                    posts={allPostsRecent?.slice(0, 4)}
                />
                <NewsletterForm />
                <Posts posts={allPostsRecent?.slice(4, 12)} />
                {allPostsRecent?.length > 12 && (
                    <>
                        <ArrayCTA />
                        <Posts posts={allPostsRecent?.slice(12)} />
                    </>
                )}
                <Pagination currentPage={currentPage} numPages={numPages} base={cleanedBase} />
            </PostLayout>
        </Layout>
    )
}

export default BlogCategory

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!, $locale: String!) {
        allPostsRecent: allMdx(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
                isFuture: { ne: true }
                frontmatter: { date: { ne: null }, locale: { eq: $locale } }
                fields: { slug: { regex: "/^/blog/" } }
            }
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
    }
`
